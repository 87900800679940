import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import useTranslations from '../components/layout/useTranslations';
import {
  HomeHeader,
  FlexGallery,
  FlexGalleryWrapper
} from '../components/layout/index.js';
import { Section, Container } from '../components/common/index.js';
import Alert from '../components/layout/Alert';

const CenterContainer = styled(Container)`
  text-align: center;
`;

const IndexPage = ({ data }) => {
  const {
    baseYourself,
    Inspiration,
    looksLike,
    sunset,
    experience,
    findWorld
  } = useTranslations();
  const hostels = data.featuredHostel.edges;
  const hostelsa = hostels.slice(0, 8);
  const hostelsb = hostels.slice(8, 14);
  const hopimage = data.hop.childImageSharp.fluid;
  const hop = {
    node: {
      featuredImage: {
        alt: 'Bedhopper Image',
        fluid: hopimage
      },
      id: 'bedhopper',
      slug: 'deals/bed-hopper'
      // title: 'For travel addicts who want to stay everywhere'
    }
  };

  var homegallery = hostelsa.concat(hop, hostelsb);

  return (
    <>
      <Helmet title="Base Backpackers Australia and New Zealand">
        <meta name="google-site-verification" content="" />
        <meta
          name="google-site-verification"
          content="75w7QAIteHVz46BO7HadHxlkq2s2-b-UNi1Ixg-RePA"
        />
        <meta
          name="description"
          content="Find the rest of the world sleeping, partying, exploring, eating and drinking with us. Experience backpacking life with new mates every day."
        />
      </Helmet>

      <HomeHeader
        backgroundImage={data.file.childImageSharp.fluid}
        alt="Base Backpackers Australia and New Zealand"
        pageTitle={findWorld}
        tagline={experience}
        propertyName="Base Magnetic Island"
        caption={sunset}
        hostels={data.allDatoCmsHostel}
      />

      <Section padding="3rem 1px">
        <Alert />
        <CenterContainer>
          <h2>{baseYourself}</h2>
        </CenterContainer>

        <FlexGalleryWrapper>
          {homegallery.map(
            ({ node: hostel }, index) =>
              hostel.id === 'bedhopper' ? (
                <FlexGallery
                  title={hostel.title}
                  fluid={hostel.featuredImage.fluid}
                  key={hostel.id}
                  alt={hostel.featuredImage.alt}
                  index={index}
                  url={`/${hostel.slug}`}
                  id={hostel.id}
                />
              ) : (
                <FlexGallery
                  title={hostel.title}
                  fluid={hostel.featuredImage.fluid}
                  key={hostel.id}
                  alt={hostel.featuredImage.alt}
                  index={index}
                  url={`/hostels/${hostel.slug}`}
                  id={hostel.id}
                />
              )
          )}
        </FlexGalleryWrapper>
      </Section>
      <Section lightBackground>
        <h2 style={{ textAlign: 'center' }}>{looksLike}</h2>
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%'
          }}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=JybCmOkJEE0"
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              top: '0',
              left: '0'
            }}
          />
        </div>
      </Section>

      <Section>
        <h2>{Inspiration}</h2>

        <FlexGalleryWrapper>
          {data.featured.edges.map(({ node: blog }) => (
            <FlexGallery
              title={blog.title}
              fluid={blog.featuredImage.fluid}
              key={blog.id}
              alt={blog.featuredImage.alt}
              url={`/${blog.destination.slug}/${blog.slug}`}
              id={blog.id}
            />
          ))}
        </FlexGalleryWrapper>
      </Section>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery($locale: String!) {
    file(relativePath: { eq: "magnetic-island4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hop: file(relativePath: { eq: "bedhopper.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featured: allDatoCmsBlog(
      filter: { locale: { eq: "en" }, featuredPost: { eq: "Yes" } }
      limit: 6
    ) {
      edges {
        node {
          id
          title

          slug
          destination {
            slug
          }
          featuredImage {
            alt
            fluid(
              maxWidth: 225
              maxHeight: 150
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
                w: "300"
                h: "200"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    featuredHostel: allDatoCmsHostel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          slug
          title
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
                w: "300"
                h: "200"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    allDatoCmsHostel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`;
